
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.modal-chat-bot-registration {
  .esmp-modal {
    width: 800px !important;
    @include for-size(phone-landscape-down) {
      width: 700px !important;
    }
    @include for-size(phone-portrait-down) {
      width: auto !important;
    }
  }
  &__error {
    margin-top: 20px;
    color: $error-color;
    font-size: 13px;
  }
}
.esmp-modal-body {
  font-size: 16px;
}
